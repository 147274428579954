<template>
  <UCard :ui="ui">
    <template #header>
      <div class="inline-flex gap-3 pt-2">
        <h3 class="font-semibold text-xl">Recent {{ local ? 'Local' : 'National' }} Topics</h3>
      </div>
    </template>
    <div v-if="['pending', 'idle'].includes(status) && topics?.length === 0" class="grid gap-4 p-6">
      <div class="space-y-2">
        <USkeleton class="h-4 w-[250px]" />
        <USkeleton class="h-4 w-[200px]" />
      </div>
      <div class="space-y-2">
        <USkeleton class="h-4 w-[250px]" />
        <USkeleton class="h-4 w-[200px]" />
      </div>
      <div class="space-y-2">
        <USkeleton class="h-4 w-[250px]" />
        <USkeleton class="h-4 w-[200px]" />
      </div>
    </div>
    <ul>
      <li v-for="topic in topics?.slice(0,10)" :key="topic.id" class=" hover:bg-gray-50 dark:hover:bg-stone-800/50 ring-off">
        <NuxtLink :href="`/topic/${topic.slug}`" class="block py-3 px-6 group">
          <p class="font-medium"><span class="group-hover:text-primary group-hover:underline">{{ topic.name }}</span> <span class="text-gray-400 text-xs font-light ml-2">{{ topic.count }} posts</span></p>
          <p v-if="topic.description" class="text-gray-500 text-xs term-description">{{ topic.description.replace(/<\/?[^>]+(>|$)/g, "") }}</p>
        </NuxtLink>
      </li>
    </ul>
    <template v-if="topics && topics.slice(10)?.length > 0" #footer>
      <h4 class="pb-2">More</h4>
      <ul>
        <li v-for="topic in topics?.slice(10)" :key="topic.id" class="inline-block mr-3">
          <NuxtLink :href="`/topic/${topic.slug}`" class="text-sm text-primary hover:underline whitespace-nowrap">{{ topic.name }}</NuxtLink>
        </li>
      </ul>
    </template>
  </UCard>
</template>

<script setup lang="ts">
import type { WP_REST_API_Tags } from 'wp-types';

const props = defineProps<{
  local: boolean
  postType?: 'drop' | 'post'
}>()

const { public: { baseURL } } = useRuntimeConfig()

const { data: topics, status, refresh } = useLazyFetch<WP_REST_API_Tags>('/wmn/v1/terms', {
  baseURL,
  query: {
    dimension: props.local ? 'popular_voices' : 'popular_firehose',
    post_type: props.postType ? props.postType : 'drop',
    _fields: 'id,name,slug,description,count'
  },
  server: false
})

const ui = {
  header: {
    background: '',
  },
  body: { padding: 'px-0 py-0 sm:p-0' },
  rounded: 'rounded-none',
  shadow: 'shadow-none',
  ring: 'ring-0 ring-transparent ring-offset-0 ring-offset-transparent',
  background: 'bg-none',
  divide: 'divide-none'
}

useIntervalFn(() => {
  refresh()
}, 60000)
</script>
